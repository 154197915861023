.skills h2 {
  font-size: 35px;
  color: #5C6E58;
  font-weight: bold;
  margin: 10px 10px 80px 10px !important;
}

.submit {
  background-color: black;
  border-radius: 5px;
  padding: 20px;
}

.skillsGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* gap: 10em; */
  gap: 20px;
  grid-auto-rows: auto;
  height: 100vh;
}

.skillsGrid img {
  height: 100px;
  width: 100px;
}

.skills h2 {
  margin-bottom: 2em;
}

.skills {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* Small Screens max width 640px */
@media screen and (max-width: 640px) {
  .skillsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    grid-auto-rows: auto;
    height: auto;
  }


  .skillsGrid img {
    height: 75px;
    width: 75px;
  }

  .skills {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

/* Medium Screens min width 641px change to 4 columns */
@media screen and (min-width: 641px) {
  .skillsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    grid-auto-rows: auto;
    height: auto;
  }


  .skillsGrid img {
    height: 100px;
    width: 100px;
  }

  .skills {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

}

@media screen and (min-width: 1080px) {
  .skillsGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 50px;
    grid-auto-rows: auto;
    height: auto
  }


  .skillsGrid img {
    height: 150px;
    width: 150px;
  }

  .skills {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}