.condiv-home {
  background-color: #5C6E58;


  .greeting {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    display: block;
    border-radius: 50%;
    margin: 40px auto 0px auto;
    max-width: 50%;
    height: auto;
  }

  @media screen and (max-width: 1024px) {
    img {
      display: block;
      margin: 40px auto 0px auto;
      max-width: 50%;
      height: auto;
    }
  }

  @media screen and (max-width: 640px) {
    img {
      display: block;
      margin: 40px auto 0px auto;
      width: 50%;
    }
  }

  @media screen and (max-width: 480px) {
    img {
      display: block;
      margin: 30px auto 0px auto;
      width: 50%;
    }
  }

  h1 {
    text-transform: uppercase;
    line-height: 30px;
    color: rgb(209, 197, 179);
    font-family: 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
    padding-bottom: 90px;
    font-size: 30px;
    text-align: left;
  }

  @media screen and (max-width: 1024px) {
    h1 {
      padding: 0px 50px 60px 20px;
      line-height: 30px;
      font-size: 30px;
    }
  }

  @media screen and (max-width: 640px) {
    h1 {
      padding: 0px 50px 0px 20px;
      line-height: 40px;
      font-size: 25px;
    }
  }

  @media screen and (max-width: 480px) {
    h1 {
      padding: 0px 50px 120px 20px;
      line-height: 30px;
      font-size: 20px;
    }
  }

  .p-text-home {
    display: flex;
    align-items: center;
    padding-left: 20px;
    line-height: 50px;
    text-transform: lowercase;
    font-weight: bold;
    color: #F2D349;
    font-family: 'Dancing Script',
      cursive;
    font-size: 70px;
  }

  @media screen and (max-width: 1024px) {
    .p-text-home {
      font-size: 59px;
    }
  }

  @media screen and (max-width: 640px) {
    .p-text-home {
      font-size: 48px;

    }
  }

  @media screen and (max-width: 480px) {
    .p-text-home {
      padding: 5px;
      font-size: 29px;
    }
  }
}