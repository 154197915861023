.social {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  bottom: 60px;
  padding-left: 10px;

}

.social i {
  padding-right: 20px;
  font-size: 40px;
  color: #9a9b9c;

}

.social i:hover {
  color: rgb(209, 197, 179);

}