#app__container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.condiv {
  background-color: #5C6E58;
  padding: 100px;
  flex: 6;

}

.subtopic {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  color: #fff;
}


// element.style {}

// .portfolio-item .item-wrap a {
//   display: block;
//   cursor: pointer;
// }

// a,
// a:visited {
//   text-decoration: none;
//   outline: 0;
//   color: #11ABB0;
//   -webkit-transition: color .3s ease-in-out;
//   -moz-transition: color .3s ease-in-out;
//   -o-transition: color .3s ease-in-out;
//   transition: color .3s ease-in-out;
// }

// *,
// *:before,
// *:after {
//   -moz-box-sizing: border-box;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
// }

// user agent stylesheet a:-webkit-any-link {
//   color: -webkit-link;
//   cursor: pointer;
//   text-decoration: underline;
// }