// .condiv-contact {
//   background-color: #5C6E58;
//   flex: 6
// }

#app__container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.condiv-contact {

  background-color: #5C6E58;

  p {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 40px;
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 90px;
    color: #fff;
    padding-top: 25px;
  }

  @media screen and (max-width: 720px) {
    p {
      line-height: 30px;
      font-size: 25px;
      padding: 5px;
    }

  }

  h1 {
    font-size: 25px;
    text-align: center;
  }

  @media screen and (min-width:320px) {
    font-size: 15px;

  }

  h3 {
    text-align: center
  }
}