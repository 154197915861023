.condiv-about {
  background-color: #5C6E58;
  flex: 6;
}

.about__content {

  .subtopic {
    font-size: 30px;
    text-align: center;
    padding: 7px;
  }

  p {

    display: inline-block;
    padding: 0px 205px 12px 200px;
    margin-bottom: 5px;
    line-height: 1.3;
    font-size: 29px;
    font-family: 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
    color: #fff;
  }

  @media screen and (max-width: 1008px) {
    p {
      font-size: 25px;
      padding: 0px 45px 9px 40px;
    }
  }

  @media screen and (max-width: 640px) {
    p {
      font-size: 22px;
      padding: 0px 27px 7px 30px;
    }
  }

  @media screen and (max-width: 480px) {
    p {
      font-size: 20px;
      padding: 0px 27px 7px 30px;

    }
  }
}